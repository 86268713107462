import { Collapse, CollapseItem } from 'vant';
import {config} from '@/utils'

export default {

    // 监听,当路由发生变化的时候执行
    watch:{
        $route(to,from){
            if(to.path =='/wap/details'){
                this.footerWaps=false
            }else{
                this.footerWaps=true
            }
        }
    },
    created() {

    },
    mounted() {

    },

    methods: {
        filingsLink() {
            window.location.href='https://beian.miit.gov.cn/'
        },
        num(v){
            if(v.name =='意见反馈' || v.name =='帮助中心'){
                window.location.href='https://support.qq.com/products/608513'

            }
            if(v.name=="联系方式"){
                // location.href=config.shiquanshimei+"/wap/about?type=4"
                this.$router.push({
                    path:"/wap/about",
                    query:{type:2}

                })
            }
            if(v.name=="公司资质"){
                this.$router.push({
                    path:"/wap/about",
                    query:{type:1}

                })
                // location.href=config.shiquanshimei+"/wap/about?type=1"
            }
            if(v.name=="人是云"){
                window.location.href='https://www.renshihr.com/'

            }
            if(v.name=="广州市南粤公证处"){
                window.location.href='http://nygzc.com.cn/H5/index'
            }
            if(v.name=="广州市南沙公证处"){
                window.location.href='https://www.nsgzc.cn/'
            }
            if(v.name=="深圳市福田公证处"){
                window.location.href='https://www.ftgzc.com/'
            }
            if(v.name=="广州越富嘉驰网络科技有限公司"){
                window.location.href='https://www.jcfintech.net.cn/'
            }

        }


    },
    components:{
        Collapse, CollapseItem,
    },

    data() {
        return {
            config,
            footerWaps:true,
            tabList: [
                {
                    name: '关于我们',
                    child: [
                        {
                            name:'联系方式'
                        },
                        // {
                        //     name:'公司资质'
                        // },
                        // {
                        //     name:'支付方式'
                        // },
                        {
                            name:'意见反馈'
                        },
                        {
                            name:'帮助中心'
                        },
                        // {
                        //     name:'条款协议'
                        // },
                    ],
                },
                {
                    name: '友情链接',
                    child: [
                        // {
                        //     name:'人是云',
                        // },
                        {
                            name:'广州市南粤公证处'
                        },
                        {
                            name:'广州市南沙公证处'
                        },
                        {
                            name:'深圳市福田公证处'
                        },
                        {
                            name:'广州越富嘉驰网络科技有限公司'
                        },
                    ],
                },

            ],

            activeMenu: [],

        }
    }
}